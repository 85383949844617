import { AttributeValue, Customer } from "@msdyn365-commerce/retail-proxy";
import { ICoreContext } from '@msdyn365-commerce/core';
import nbNO from './locale/nb-NO.json';
import enUS from './locale/en-US.json';
import { decodeBase64String, isBase64EncodePrefixed } from "../base64";

export function getAttribute(attributes: AttributeValue[] | undefined, attributeName: string): AttributeValue {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    const attribute = attributes?.find(x => x.Name?.replace('{base64}:', '') === attributeName);

    if (!attribute) {
        throw new Error(`Attribute ${attributeName} not found.`);
    }

    return attribute;
}

export function getAttributeNumber(attributes: AttributeValue[] | undefined, attributeName: string): number {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    const attribute = attributes?.find(x => x.Name?.replace('{base64}:', '') === attributeName);
    let result: number | undefined;

    if (!attribute) {
        throw new Error(`Attribute ${attributeName} not found.`);
    }

    switch (attribute.DataTypeValue) {
        case 1: // Currency
            result = attribute.CurrencyValue;
            break;
        case 3: // Decimal
            result = attribute.FloatValue;
            break;
        case 4: // Integer
            result = attribute.IntegerValue;
            break;
        case 0: // None
        case 2: // DateTime
        case 5: // Text
        case 6: // TrueFalse
            throw new Error(`Attribute ${attributeName} is not a number.`);
    }

    return result || 0;
}

export function getAttributeNumberOrDefault(attributes: AttributeValue[] | undefined, attributeName: string, defaultValue: number): number {
    if (!attributes) {
        return defaultValue;
    }

    const attribute = attributes?.find(x => x.Name?.replace('{base64}:', '') === attributeName);
    let result: number | undefined;

    if (!attribute) {
        return defaultValue;
    }

    switch (attribute.DataTypeValue) {
        case 1: // Currency
            result = attribute.CurrencyValue;
            break;
        case 3: // Decimal
            result = attribute.FloatValue;
            break;
        case 4: // Integer
            result = attribute.IntegerValue;
            break;
        case 0: // None
        case 2: // DateTime
        case 5: // Text
        case 6: // TrueFalse
            result = defaultValue;
            break;
    }

    return result || 0;
}

export function getAttributeString(attributes: AttributeValue[] | undefined, attributeOrName: AttributeValue | string, suffix?: string): string {
    if (!attributes) {
        throw new Error(`No attributes found.`);
    }

    let attribute, result;

    if (typeof attributeOrName === 'string') {
        attribute = attributes?.find(x => x.Name?.replace('{base64}:', '') === attributeOrName);
    } else {
        attribute = attributeOrName;
    }

    switch (attribute?.DataTypeValue) {
        case 0: // None
            result = `-`;
            break;
        case 1: // Currency
            result = attribute.CurrencyValue?.toString();
            break;
        case 2: // DateTime
            result = attribute.DateTimeOffsetValue?.toUTCString();
            break;
        case 3: // Decimal
            result = attribute.FloatValue?.toString();
            break;
        case 4: // Integer
            result = attribute.IntegerValue?.toString();
            break;
        case 5: // Text
            result = attribute.TextValue?.toString();
            break;
        case 6: // TrueFalse
            result = attribute.BooleanValue?.toString();
            break;
    }

    if (!result) {
        throw new Error(`Attribute ${attributeOrName} not found`);
    }

    if (isBase64EncodePrefixed(attribute)) {
        result = decodeBase64String(result);
    }

    if (result !== '-' && suffix) {
        result += suffix;
    }

    return result;
}

export function getAttributeStringOrDefault<T>(attributes: AttributeValue[] | undefined, attributeName: string, defaultValue: T, suffix?: string): T | string {
    if (!attributes) {
        return defaultValue;
    }

    const attribute = attributes?.find(x => x.Name?.replace('{base64}:', '') === attributeName);
    let result;

    if (!attribute) {
        return defaultValue;
    }

    switch (attribute?.DataTypeValue) {
        case 0: // None
            result = `-`;
            break;
        case 1: // Currency
            result = attribute.CurrencyValue?.toString();
            break;
        case 2: // DateTime
            result = attribute.DateTimeOffsetValue?.toUTCString();
            break;
        case 3: // Decimal
            result = attribute.FloatValue?.toString();
            break;
        case 4: // Integer
            result = attribute.IntegerValue?.toString();
            break;
        case 5: // Text
            result = attribute.TextValue?.toString();
            break;
        case 6: // TrueFalse
            result = attribute.BooleanValue?.toString();
            break;
        default:
            result = '';
            break;
    }

    if (result && isBase64EncodePrefixed(attribute)) {
        result = decodeBase64String(result);
    }

    if (result && result !== '-' && suffix) {
        result += suffix;
    }

    return result || '';
}

export function getAttributesLocale(lang: string): typeof nbNO {
    switch (lang) {
        case 'nb-NO':
            return nbNO;
        case 'en-US':
            return {...nbNO, ...enUS};
        default:
            return nbNO;
    }
}

export function hasAttribute(attributes: AttributeValue[] | undefined, attributeName: string): boolean {
    if (!attributes) {
        return false;
    }

    return attributes?.some(x => x.Name === attributeName);
}

export function hasAccessToBuy(context: ICoreContext, customer: Customer | undefined, productAttributes: AttributeValue[] | undefined) {
    if (!productAttributes || productAttributes.length === 0) {
        // No product attributes available, default to true
        return true;
    }

    const locale = getAttributesLocale(context.request.locale);
    const licensesToCheck = [
        locale.alcoholProduct,
        locale.bama,
        locale.cocacola,
        locale.dutyFreeSugar,
        locale.nortura,
        locale.tobacco,
        locale.transit
    ];
    let hasAccess = true;

    const forsvaretAttributeId = context.request.app.config?.forsvaretAttributeRecordId;

    if (forsvaretAttributeId) {
        const productForsvaretAttribute = productAttributes?.find(x => x.RecordId === Number(forsvaretAttributeId))?.IntegerValue || 0;
        const customerForsvaretAttribute = customer?.Attributes?.find(x => x.RecordId === Number(forsvaretAttributeId))?.AttributeValue?.IntegerValue || 0;

        // If customer has Forsvaret attribute set to 1 don't allow buying of products with Forsvaret attribute set to 0
        if (customerForsvaretAttribute === 1 && productForsvaretAttribute === 0) {
            return false;
        }
    }

    if (checkForSvalbard(context, customer, productAttributes) === false) {
        return false;
    }

    for (const license of licensesToCheck) {
        const productAttribute = Number(productAttributes?.find(x => x.Name === license)?.TextValue);
        const customerAttribute = Number(customer?.Attributes?.find(x => x.Name === license)?.AttributeValue?.StringValue);

        // Product doesn't have the attribute. Default to has access
        if (isNaN(productAttribute) || productAttribute === 0) {
            continue;
        }

        // Product has the attribute and it's more than 0, but customer doesn't have it at all. No access
        if (isNaN(customerAttribute)) {
            hasAccess = false;
            break;
        }

        // Product has the attribute, but customer has a lower value. No access
        if (customerAttribute < productAttribute) {
            hasAccess = false;
            break;
        }
    }

    return hasAccess;
}

function checkForSvalbard(context: ICoreContext, customer: Customer | undefined, productAttributes: AttributeValue[] | undefined) {
    const svalbardAttributeId = context.request.app.config?.svalbardAttributeRecordId;
    const blockedSvalbardAttributeId = context.request.app.config?.blockedSvalbardAttributeRecordId;

    if (!svalbardAttributeId || !blockedSvalbardAttributeId) {
        return true;
    }

    const customerSvalbardAttribute = customer?.Attributes?.find(x => x.RecordId === Number(svalbardAttributeId))?.AttributeValue?.IntegerValue || 0;
    const productSvalbardAttribute = productAttributes?.find(x => x.RecordId === Number(blockedSvalbardAttributeId))?.IntegerValue || 0;

    if (productSvalbardAttribute > 0 && customerSvalbardAttribute > 0) {
        return false;
    }

    return true;
}
